import request from '../utils/request'

/**
 * 获取账单列表
 */
export const getBillList = (params: any) => {
    return request({
        url: '/save/open/getRechargeLog',
        method: 'get',
        data: params
    })
}

/**
 * 获取账单详情数据
 * @param params object 账单id
 */
export const getBillDetail = (params: any) => {
    return request({
        url: '/save/sellerBill/detail',
        method: 'get',
        data: params
    })
}

/**
 * 获取账单详情数据
 * @param params object 账单id
 */
export const payBillByAlipay = (params: any) => {
    return request({
        url: '/save/sellerBill/alipay',
        method: 'post',
        data: params
    })
}
